<template>
  <b-card

      no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >


          <b-button
              variant="primary"
              :to="{ name: 'creer-concession'}"
          >
            Créer concession
          </b-button>
        </b-col>

        <!-- Search -->

      </b-row>

    </div>
    <b-table v-if="items"
             ref="selectableTable"
             :fields="fields"
             :items="items"

             responsive
             class="mb-0"

    >
      <template #cell(actions)="data">

        <b-button :to="{ name: 'editer-concession', params : {id : data.item.id }}" v-ripple.400="'rgba(113, 102, 240, 0.15)'"  size="sm" variant="outline-primary"  >
          Editer
        </b-button>
      </template>
    </b-table>



  </b-card>
</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'


export default {
  name: "ConcessionsList",
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      fields: ['id',  {key : 'libelle', label : 'Concession'},  {key : 'actions'  }],      /* eslint-disable global-require */
      items: [],
 

    }
  },
  created() {
    let _this = this;
    this.$store.dispatch('concessionModule/fetchAll').then(function(response){
      _this.items = response.data;
    })
  },
  methods: {

  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
